.root {
  --scan-container-size: 150px;
  --scan-border-width: 4px;
  --scan-border-color: #9ac8db;
  --scan-border-size: 30px;

  margin: 50px 0px;
  width: var(--scan-container-size);
  height: var(--scan-container-size);
  position: relative;
}

.line {
  border: 2px solid tomato;
  border-radius: 5px;
  animation: mover 2s infinite alternate;
  box-shadow: 0px 0px 5px 0px tomato;
}

.container {
  margin: 0px;

  background: linear-gradient(to right, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 0 0,
    linear-gradient(to right, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 0 100%,
    linear-gradient(to left, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 100% 0,
    linear-gradient(to left, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 100% 100%,
    linear-gradient(to bottom, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 0 0,
    linear-gradient(to bottom, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 100% 0,
    linear-gradient(to top, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 0 100%,
    linear-gradient(to top, var(--scan-border-color) var(--scan-border-width), transparent var(--scan-border-width)) 100% 100%;

  background-repeat: no-repeat;
  background-size: var(--scan-border-size) var(--scan-border-size);
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(var(--scan-container-size));
  }
}
